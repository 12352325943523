var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showFooter()
    ? _c(
        "footer",
        {
          class: [
            _vm.channel.skin_background_footer
              ? "bg-" + _vm.channel.skin_background_footer
              : "bg-dark",
            _vm.channel.skin_footer_text
              ? "text-" + _vm.channel.skin_footer_text
              : "text-light",
          ],
        },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                { staticClass: "py-6", attrs: { "align-v": "center" } },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "text-center text-md-left",
                      attrs: { cols: "12", md: "6" },
                    },
                    [
                      _c("p", { staticClass: "m-0 smallest" }, [
                        _vm._v(
                          "© " +
                            _vm._s(_vm.channel.client_name) +
                            " " +
                            _vm._s(_vm._f("moment")(new Date(), "YYYY")) +
                            ". " +
                            _vm._s(_vm.$t("footer.rights")) +
                            "."
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "text-center text-md-right pt-2 pt-lg-0",
                      attrs: { cols: "12", md: "6" },
                    },
                    [
                      _c("p", { staticClass: "m-0 smallest" }, [
                        _vm._v(_vm._s(_vm.$t("footer.powered")) + " "),
                        _c(
                          "a",
                          {
                            staticClass: "smallest",
                            attrs: {
                              href: "https://creomediamanager.com",
                              target: "_blank",
                            },
                          },
                          [_vm._v("Creo Media Manager")]
                        ),
                        _vm._v("."),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }