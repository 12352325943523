<template>
	<footer :class="[channel.skin_background_footer ? 'bg-'+channel.skin_background_footer : 'bg-dark', channel.skin_footer_text ? 'text-'+channel.skin_footer_text : 'text-light']"
			v-if="showFooter()">
		<b-container>
			<b-row 	align-v="center"
					class="py-6">
				<b-col 	cols="12"
						md="6"
						class="text-center text-md-left">
					<p class="m-0 smallest">&copy; {{ channel.client_name }} {{ new Date() | moment('YYYY') }}. {{ $t('footer.rights') }}.</p>
				</b-col>
				<b-col 	cols="12"
						md="6"
						class="text-center text-md-right pt-2 pt-lg-0">
					<p class="m-0 smallest">{{ $t('footer.powered') }} <a href="https://creomediamanager.com" target="_blank" class="smallest">Creo Media Manager</a>.</p>
				</b-col>
			</b-row>
		</b-container>
	</footer>
</template>
<script>
	export default {
		computed: {
			asset() {
				return this.$store.getters.asset
			},
			channel() {
				return this.$store.getters.channel
			},
			stream() {
				return this.$store.getters.stream
			}
		},
		methods: {
			showFooter() {
				if(this.$route.name === 'preview') return true
				if(this.$route.name === 'asset' && this.asset && (this.asset.broadcasting === 1 && this.stream)) return true
				if(this.$route.name === 'asset' && this.asset && (this.asset.broadcasting === 1 && this.asset.type === 5)) return true
				if(this.$route.name === 'asset' && this.asset && this.asset.type === 1) return true
				if(this.$route.name === 'asset' && this.asset && this.asset.type === 4 && this.asset.status_id == 5) return true
				return false
			}
		}
	}
</script>
