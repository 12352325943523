<template>
	<div 	id="app"
			class="bg-cover"
			:class="this.sidebar ? 'sidebar-open' : null"
			:style="backgroundImage() && isPrePostLive() ? backgroundImage() : null"
			v-if="loaded">
		<video class="bg-video" autoplay loop muted playsinline webkit-playsinline v-if="asset && backgroundVideo() && isPrePostLive()">
			<source :src="backgroundVideo()" :type="genFileType()">
		</video>
		<Header />
		<router-view :key="$route.fullPath" class="main" />
		<Footer />
	</div>
	<Error :error="error" v-else-if="error" />
	<Loading v-else />
</template>
<style lang="scss">
	@import '@/assets/scss/main.scss';
</style>
<script>
	window.Pusher = require('pusher-js')
	import Vue from 'vue';
	import _ from 'lodash'
	import Error from '@/components/Error.vue'
	import Footer from '@/components/Footer.vue'
	import Header from '@/components/Header.vue'
	import Loading from '@/components/Loading.vue'
	export default {
		name: 'App',
		components: {
			Error,
			Footer,
			Header,
			Loading
		},
		computed: {
			asset() {
				return this.$store.getters.asset
			},
			channel() {
				return this.$store.getters.channel
			},
			loaded() {
				return this.$store.getters.loaded
			},
			sidebar() {
				return this.$store.getters.sidebar
			},
			type() {
				return this.$store.getters.type
			},
		},
		data () {
			return {
				error: null,
				currentLive: null,
				firstAssetChild: false
			}
		},
		methods: {
			getParams() {
				const isSSO = this.$route.query.sso_type && this.$route.query.sso_token || localStorage.getItem('sso_token') && localStorage.getItem('sso_type');
				let data = { slug: window.location.pathname.split('/').pop() }
				if (this.$route.query.token) {
					data.token = this.$route.query.token
					this.setAttendee(data.token)
				}
				if (this.$route.query.password) data.password = this.$route.query.password
				if (isSSO) this.axiosAddSSOInterceptor()
				return data
			},
			setAttendee(token) {
				try {
					const attendee = JSON.parse(atob(token.split('.')[1]))
					this.$store.dispatch('setAttendee', attendee)
				}
				catch (err) {
					console.log(err);
				}
			},
			axiosAddSSOInterceptor() {
				const sso_type = this.$route.query.sso_type ? this.$route.query.sso_type : localStorage.getItem('sso_type')
				const sso_token = this.$route.query.sso_token ? this.$route.query.sso_token : localStorage.getItem('sso_token')
				if (!sso_type || !sso_token) return 
				this.setAttendee(sso_token)
				Vue.axios.interceptors.request.use((config) => {
					config.headers['X-SSO-TYPE'] = sso_type;
					config.headers['X-SSO-TOKEN'] = sso_token;
					return config;
				}, (error) => {
					return Promise.reject(error);
				});
			},
			getType() {
				let data = _.cloneDeep(this.getParams())
				this.$store.dispatch('type', data)
				.then(response => {
					if (response.type == 'category' && response.asset) {
						this.firstAssetChild = 'asset'
						data.slug = response.asset
					}
					data.api_key = response.api_key
					this.getChannel(data);
				})
				.catch(err => {
					if (err.response.status === 403) {
						localStorage.clear()
						if (err.response.data.ssoLogin) {
							const config = err.response.data.config;
							window.location = `${process.env.VUE_APP_ACCESS_URL}${data.slug}?sso=${config.type}&ssoKey=${config.key}&ref=${encodeURI(window.location.href)}`;
							return;
						}
						window.location = `${process.env.VUE_APP_ACCESS_URL}${data.slug}?ref=${encodeURI(window.location.href)}`;
					}
					else if (err.response.status === 404) {
						this.error = "asset";
						this.$store.dispatch('loaded', true)
					}
				})
			},
			getPreview(data) {
				data.secret = this.$route.query.secret
				this.$store.dispatch('previewAsset', data)
				.then(response => {
					window.document.title = this.channel && this.channel.client_name ? this.stripTitle(response.title) + ' | ' + this.channel.client_name : this.stripTitle(response.title)
					if (response.type === 'stream' || (response.type === 4 && ![3,5].includes(response.status_id)) || response.type === 5) {
						this.connectToPrivatePusher(response)
					}
					if(response.language && response.language.code && response.language.code !== this.$i18n.locale) {
						this.$i18n.locale = response.language.code
					}
				})
				.catch(() => {
					this.error = 'preview'
				})
			},
			getAsset(data) {
				this.$store.dispatch('asset', data)
				.then(response => {
					window.document.title = this.channel && this.channel.client_name ? this.stripTitle(response.title) + ' | ' + this.channel.client_name : this.stripTitle(response.title)
					if (response.type === 'stream' || (response.type === 4 && ![3,5].includes(response.status_id)) || response.type === 5) {
						this.connectToCloudfront(response)
					}
					if(response.language && response.language.code && response.language.code !== this.$i18n.locale) {
						this.$i18n.locale = response.language.code
					}
				})
				.catch(err => {
					if (err.response.status === 403) {
						window.location = `${process.env.VUE_APP_ACCESS_URL}${data.slug}?ref=${encodeURI(window.location.href)}`;
					}
					else if (err.response.status === 404) {
						this.error = "asset";
						this.$store.dispatch('loaded', true)
					}
				})
			},
			getCategory(data) {
				console.log('data2', data);
				this.$store.dispatch('category', data)
				.then(() => {
				})
				this.$store.dispatch('assets', data)
				.then(() => {
					this.$router.push({ name: 'category', params: { slug: this.$route.params.slug } }).catch(() => { });
				})
			},
			connectToPusher(data) {
				try {
					var pusher = new window.Pusher(process.env.VUE_APP_PUSHER, {
						cluster: 'eu'
					})
					pusher.connection.bind('error', (err) => {
						throw err
					})
					var assetChannel = pusher.subscribe(`${data.asset_category_key}`)
					assetChannel.bind('my-event', () => {
						this.$store.dispatch('assetJson', data)
					})
					this.$store.dispatch('pusher', pusher)
				}
				catch(err) {
					console.log('problem connecting to pusher, using fallback instead', err)
					throw err
				}
			},
			connectToPrivatePusher(data) {
				try {
					var pusher = new window.Pusher(process.env.VUE_APP_PUSHER, {
						cluster: 'eu',
						authEndpoint: process.env.VUE_APP_API_PUSHER,
						encrypted: true,
						auth: {
							headers: {
								Authorization: `Bearer ${this.$route.query.secret}`
							}
						}
					})
					var assetChannel = pusher.subscribe(`private-asset.${data.asset_category_key}`)
					assetChannel.bind('my-event', () => {
						let data = { slug: window.location.pathname.split('/').pop(), secret: this.$route.query.secret }
						this.$store.dispatch('previewAsset', data)
					})
					this.$store.dispatch('pusher', pusher)
				}
				catch(err) {
					console.log('problem connecting to pusher, using fallback instead', err)
					throw err
				}
			},
			connectToCloudfront(data) {
				this.$store.dispatch('assetJson', data)
				setInterval(() => {
					this.$store.dispatch('assetJson', data)
				}, 2000)
			},
			getChannel(data) {
				this.$store.dispatch('channel', data)
				.then(response => {
					window.document.title = this.stripTitle(response.title)
					if(response.favicons.length) document.getElementById('favicon').href = response.favicons_path + response.favicons[0].filename
					this.setLanguage(response)
					this.setTheme(response)
					this.setFonts(response)
					window.location.pathname.split('preview').length > 1 
						? this.getPreview(data) 
						: this.type.type === 'category' && this.firstAssetChild !== 'asset' ? this.getCategory(data) : this.getAsset(data) 
				})
				.catch(() => {
					this.error = 'channel'
				})
				.finally(() => {
					this.$store.dispatch('loaded', true)
				})
			},
			setLanguage(data) {
				const lang = data.language_id && data.languages && data.languages.length ? data.languages.find(language => language.id == data.language_id).code : null
				this.$i18n.locale = lang ? lang : 'en'
			},
			setTheme(data) {
				const primary = data.skin_color_primary ? '#' + data.skin_color_primary : '#5EB787'
				const secondary = data.skin_color_secondary ? '#' + data.skin_color_secondary : '#F4ABAD'
				const text = data.skin_color_text ? '#' + data.skin_color_text : '#000000'
				const background = data.skin_color_background ? '#' + data.skin_color_background : '#FFFFFF'
				const alt = data.skin_color_alternate_background ? '#' + data.skin_color_alternate_background : '#FFFFFF'
				const dark = data.skin_color_dark ? '#' + data.skin_color_dark : '#333333'
				const light = data.skin_color_light ? '#' + data.skin_color_light : '#F2F2F2'
				const borderRadius = data.skin_border_radius ? data.skin_border_radius : '4px'
				document.documentElement.style.setProperty('--color-primary', primary)
				document.documentElement.style.setProperty('--color-secondary', secondary)
				document.documentElement.style.setProperty('--color-text', text)
				document.documentElement.style.setProperty('--color-background', background)
				document.documentElement.style.setProperty('--color-alt', alt)
				document.documentElement.style.setProperty('--color-dark', dark)
				document.documentElement.style.setProperty('--color-light', light)
				document.documentElement.style.setProperty('--border-radius', borderRadius)
			},
			setFonts(data) {
				let primaryFont = false
				let secondaryFont = false
				let fontStyle = document.createElement('style')
				if(data.fonts.length) {
					data.fonts.forEach(font => {
						const fontFace = new FontFace(this.genLabel(font.label), `url(${data.font_path}${font.filename})`)
						fontFace.load().then(() => {
							fontStyle.appendChild(document.createTextNode(`@font-face{font-family: '${this.genLabel(font.label)}'; src: url('${data.font_path}${font.filename}'); font-weight: ${this.genWeight(font.weight)}}`));
							if(font.primary) {
								primaryFont = true
								document.documentElement.style.setProperty('--font-primary', this.genLabel(font.label))
							} else {
								secondaryFont = true
								document.documentElement.style.setProperty('--font-secondary', this.genLabel(font.label))
							}
						})
					})
					if(!primaryFont) { document.documentElement.style.setProperty('--font-primary', this.genLabel(data.fonts[0].label)) }
					if(!secondaryFont) { document.documentElement.style.setProperty('--font-secondary', this.genLabel(data.fonts[0].label)) }
				} else {
					document.documentElement.style.setProperty('--font-primary', 'HelveticaNeue, Helvetica, Arial, Sans-serif')
					document.documentElement.style.setProperty('--font-secondary', 'HelveticaNeue, Helvetica, Arial, Sans-serif')
				}
				document.head.appendChild(fontStyle)
			},
			genLabel(label) {
				return label.substring(0, label.indexOf('-'))
			},
			genWeight(weight) {
				if(weight === 'thin') return 100
				if(weight === 'extralight') return 200
				if(weight === 'light') return 300
				if(weight === 'medium') return 500
				if(weight === 'semibold') return 600
				if(weight === 'bold') return 700
				if(weight === 'extrabold') return 800
				if(weight === 'black') return 900
				return 400
			},
			genFileType() {
				const ext = this.asset.background ? this.asset.background.url.split('.').pop() : null
				if(ext) return this.asset.background.type + '/' + ext
				return null
			},
			isPrePostLive() {
				if(this.$route.name === 'preview') return false
				return (this.asset && [0, 2, 3].includes(this.asset.broadcasting) && (this.asset.type === 5 || ![3,5].includes(this.asset.status_id))) ? true : false
			},
			backgroundImage() {
				const background = this.asset && this.asset.background && this.asset.background.type === "image" && this.asset.background.url
				if(background) return 'background-image: url('+this.asset.background.url+')'
				if(this.asset && this.asset.poster) return 'background-image: url('+this.asset.poster+')'
				return null
			},
			backgroundVideo() {
				const background = this.asset.background && this.asset.background.type === "video" && this.asset.background.url
				if(background) return this.asset.background.url
				return null
			},
			stripTitle(title) {
				let regex = /(<([^>]+)>)/ig
				return title.replace(regex, "")
			}
		},
		mounted() {
			console.info('[ APP VERSION ' + this.$store.getters.version + ' ]')
		},
		watch: {
			asset(data) {
				if(data && data.language && data.language.code && data.language.code !== this.$i18n.locale) {
					this.$i18n.locale = data.language.code
				}
				if(data.broadcasting !== this.currentLive) {
					this.$store.dispatch('sidebar', null)
				}
				this.currentLive = this.asset.broadcasting
			},
			'$route'() {
				if(!this.channel) this.getType()
			}
		}
	}
</script>
