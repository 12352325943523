var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { class: _vm.headerClasses() },
    [
      _c(
        "b-container",
        [
          _c(
            "b-row",
            { attrs: { "align-v": "center" } },
            [
              _vm.getLogo()
                ? _c("b-col", { attrs: { cols: "auto" } }, [
                    _c("img", {
                      style: _vm.channel.skin_header_logo_height
                        ? "height: " + _vm.channel.skin_header_logo_height
                        : null,
                      attrs: { src: _vm.getLogo() },
                    }),
                  ])
                : _vm._e(),
              _vm.showCalendar()
                ? _c(
                    "b-col",
                    {
                      staticClass: "ml-auto text-md-right",
                      attrs: { cols: "auto" },
                    },
                    [
                      _c("ul", { staticClass: "menu" }, [
                        _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "small",
                              attrs: {
                                href: _vm.asset.calender_url,
                                target: "_blank",
                                title: _vm.$t("common.add-reminder"),
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "d-block d-lg-none small" },
                                [_vm._v(_vm._s(_vm.$t("common.reminder")))]
                              ),
                              _c(
                                "span",
                                { staticClass: "d-none d-lg-block small" },
                                [_vm._v(_vm._s(_vm.$t("common.add-reminder")))]
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  )
                : _vm.useMenu()
                ? _c(
                    "b-col",
                    {
                      staticClass: "ml-auto text-md-right",
                      attrs: { cols: "auto" },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "toggle",
                          class: _vm.sidebar ? "visible" : null,
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.toggleSidebar(_vm.getSidebarVal())
                            },
                          },
                        },
                        [
                          _c("span", { class: _vm.toggleClasses() }),
                          _c("span", { class: _vm.toggleClasses() }),
                          _c("span", { class: _vm.toggleClasses() }),
                        ]
                      ),
                      _c("ul", { staticClass: "menu live" }, [
                        _vm.showAsk()
                          ? _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "small",
                                  attrs: {
                                    href: "#",
                                    title: _vm.$t("common.ask-question"),
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      _vm.asset.asset_qa &&
                                      _vm.asset.asset_qa.sidebar &&
                                      _vm.sidebar === "ask"
                                        ? _vm.toggleSidebar()
                                        : _vm.asset.asset_qa.sidebar
                                        ? _vm.toggleSidebar("ask")
                                        : _vm.$scrollTo("#ask")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.asset.asset_qa.headline
                                          ? _vm.asset.asset_qa.headline
                                          : _vm.$t("common.ask-question")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _vm.showChat()
                          ? _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "small",
                                  attrs: {
                                    href: "#",
                                    title: _vm.$t("common.chat"),
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      _vm.sidebar === "chat"
                                        ? _vm.toggleSidebar()
                                        : _vm.toggleSidebar("chat")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("common.chat")) + " "
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _vm.showPoll()
                          ? _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "small",
                                  attrs: {
                                    href: "#",
                                    title: _vm.$t("common.poll"),
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      _vm.sidebar === "poll"
                                        ? _vm.toggleSidebar()
                                        : _vm.toggleSidebar("poll")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("common.poll")) + " "
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _vm.showQueButtons()
                          ? _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "small",
                                  attrs: {
                                    href: "#",
                                    title: _vm.$t("questions.add-question"),
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.$bvModal.show(
                                        "addQuestionModal"
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("questions.add-question")) +
                                      " "
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _vm.showQueButtons() &&
                        _vm.questions &&
                        _vm.questions.moderator &&
                        _vm.questions.moderator.length
                          ? _c(
                              "li",
                              [
                                _c(
                                  "download-excel",
                                  {
                                    attrs: {
                                      data: _vm.questions.moderator,
                                      fields: _vm.exportFields,
                                      type: "xls",
                                      name:
                                        _vm.genFilename(_vm.asset.title) +
                                        ".xls",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "small",
                                        attrs: {
                                          href: "#",
                                          title: _vm.$t(
                                            "questions.export-data"
                                          ),
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return null.apply(null, arguments)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("questions.export-data")
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }