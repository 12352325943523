import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		access: null,
		asset: null,
		assets: [],
		attendee: null,
		bigSlideRatio: null,
		category: null,
		channel: null,
		cookie: null,
		currentSlide: null,
		currentTime: null,
		downloading: null,
		loaded: false,
		pusher: null,
		questionPassword: null,
		questions: null,
		questionType: null,
		sidebar: null,
		stream: null,
		type: null,
		viewSize: false,
		viewSlide: null,
		version: process.env.VUE_APP_VERSION || '0'
	},
	getters: {
		access: state => {
			return state.access
		},
		asset: state => {
			return state.asset
		},
		assets: state => {
			return state.assets
		},
		attendee: state => {
			return state.attendee
		},
		bigSlideRatio: state => {
			return state.bigSlideRatio
		},
		category: state => {
			return state.category
		},
		channel: state => {
			return state.channel
		},
		cookie: state => {
			return state.cookie
		},
		currentSlide: state => {
			return state.currentSlide
		},
		currentTime: state => {
			return state.currentTime
		},
		downloading: state => {
			return state.downloading
		},
		loaded: state => {
			return state.loaded
		},
		pusher: state => {
			return state.pusher
		},
		questionPassword: state => {
			return state.questionPassword
		},
		questions: state => {
			return state.questions
		},
		questionType: state => {
			return state.questionType
		},
		sidebar: state => {
			return state.sidebar
		},
		stream: state => {
			return state.stream
		},
		type: state => {
			return state.type
		},
		version: state => {
			return state.version
		},
		viewSize: state => {
			return state.viewSize
		},
		viewSlide: state => {
			return state.viewSlide
		}
	},
	mutations: {
		access: (state, data) => {
			state.access = data
		},
		asset: (state, data) => {
			if (state.asset) {
				const currentDate = new Date(state.asset.updated_at)
				const newDate = new Date(data.updated_at)
				if (newDate.getTime() > currentDate.getTime())  {
					state.asset = data
				}
			} 
			else {
				state.asset = data
			}	
		},
		assets: (state, data) => {
			state.assets = data
		},
		attendee: (state, data) => {
			state.attendee = data
		},
		bigSlideRatio: (state, data) => {
			state.bigSlideRatio = data
		},
		category: (state, data) => {
			state.category = data
		},
		channel: (state, data) => {
			state.channel = data
		},
		cookie: (state, data) => {
			state.cookie = data
		},
		currentSlide: (state, data) => {
			state.currentSlide = data
		},
		currentTime: (state, data) => {
			state.currentTime = data
		},
		downloading: (state, data) => {
			state.downloading = data
		},
		loaded: (state, data) => {
			state.loaded = data
		},
		pusher: (state, data) => {
			state.pusher = data
		},
		questionPassword: (state, data) => {
			state.questionPassword = data
		},
		questions: (state, data) => {
			state.questions = data
		},
		questionType: (state, data) => {
			state.questionType = data
		},
		sidebar: (state, data) => {
			state.sidebar = data
		},
		stream: (state, data) => {
			state.stream = data
		},
		type: (state, data) => {
			state.type = data
		},
		viewSize: (state, data) => {
			state.viewSize = data
		},
		viewSlide: (state, data) => {
			state.viewSlide = data
		}
	},
	actions: {
		asset({ commit }, data) {
			return new Promise((resolve, reject) => {
				let url = `public/assets/${data.slug}`
				Vue.axios.get(url, {
					params: {
						token: data.token,
						password: data.password,
					}
				})
				.then((response) => {
					commit('asset', response.data)
					resolve(response.data)
				})
				.catch((error) => {
					reject(error)
				})
			})
		},
		assetJson({ commit }, data) {
			return new Promise((resolve, reject) => {
				let url = data.pusher_fallback
				Vue.axios.get(url)
				.then((response) => {
					commit('asset', response.data)
					resolve(response.data)
				})
				.catch((error) => {
					reject(error)
				})
			})
		},
		assets({ commit, state }, data) {
			return new Promise((resolve, reject) => {
				let url = `public/categories/assets/${data.slug}`
				Vue.axios.get(url, {
					params: {
						api_key: state.channel.api_key,
						category_id: data.category_id ? data.category_id : null,
						types: data.types ? data.types : null
					}
				})
				.then((response) => {
					commit('assets', response.data)
					resolve(response.data)
				})
				.catch((error) => {
					reject(error)
				})
			})
		},
		category({ commit, state }, data) {
			return new Promise((resolve, reject) => {
				let url = `public/categories/${data.slug}`
				Vue.axios.get(url, {
					params: {
						api_key: state.channel.api_key
					}
				})
				.then((response) => {
					commit('category', response.data)
					resolve(response.data)
				})
				.catch((error) => {
					reject(error)
				})
			})
		},
		channel({ commit }, data) {
			return new Promise((resolve, reject) => {
				let url = `public/channel`
				Vue.axios.get(url, {
					params: {
						api_key: data.api_key,
						categories: data.categories ? data.categories : 0,
					}
				})
				.then((response) => {
					commit('channel', response.data)
					resolve(response.data)
				})
				.catch((error) => {
					reject(error)
				})
			})
		},
		cookie({ commit }, data) {
			commit('cookie', data)
		},
		deleteQuestion(_, data) {
			return new Promise((resolve, reject) => {
				let url = `public/questions/${data.slug}/${data.id}`
				Vue.axios.delete(url, {
					params: {
						password: data.password,
						moderator_password: data.moderator_password,
						token: data.token
					}
				})
				.then((response) => {
					resolve(response.data)
				})
				.catch((error) => {
					reject(error)
				})
			})
		},
		download({ commit }, data) {
			return new Promise((resolve, reject) => {
				let url = `public/downloads/${data.type}/${data.slug}`
				Vue.axios.get(url, {
					params: {
						hash: data.hash,
						token: data.token,
						password: data.password,
					}
				})
				.then((response) => {
					commit('downloading', null)
					resolve(response)
				})
				.catch((error) => {
					commit('downloading', null)
					reject(error)
				})
			})
		},
		downloadFromUrl({ commit }, data) {
			return new Promise((resolve, reject) => {
				let url = data.url
				Vue.axios.get(url, {
					responseType: 'blob',
					onDownloadProgress: (event) => {
						commit('downloading', Math.round((event.loaded / event.total)*100))
					}
				})
				.then((response) => {
					commit('downloading', null)
					resolve(response)
				})
				.catch((error) => {
					commit('downloading', null)
					reject(error)
				})
			})
		},
		getQuestions({ commit }, data) {
			return new Promise((resolve, reject) => {
				let url = `public/questions/${data.slug}`
				Vue.axios.get(url, {
					params: {
						password: data.password,
						moderator_password: data.moderator_password,
						token: data.token
					}
				})
				.then((response) => {
					commit('questions', response.data)
					resolve(response.data)
				})
				.catch((error) => {
					reject(error)
				})
			})
		},
		loaded({ commit }, data) {
			commit('loaded', data)
		},
		postQuestion(_, data) {
			return new Promise((resolve, reject) => {
				let url = `public/questions/${data.slug}`
				Vue.axios.post(url, data.data)
				.then((response) => {
					resolve(response.data)
				})
				.catch((error) => {
					reject(error)
				})
			})
		},
		previewAsset({ commit }, data) {
			return new Promise((resolve, reject) => {
				let url = `public/assets/${data.slug}`
				Vue.axios.get(url, {
					headers: {
						'Authorization': `Bearer ${data.secret}`
					},
					params: {
						token: data.token,
						password: data.password,
					}
				})
				.then((response) => {
					commit('asset', response.data)
					resolve(response.data)
				})
				.catch((error) => {
					reject(error)
				})
			})
		},
		pusher({ commit }, data) {
			commit('pusher', data)
		},
		setAccess({ commit }, data) {
			localStorage.setItem('access', JSON.stringify(data))
			commit('access', data)
		},
		setAsset({ commit }, data) {
			commit('asset', data)
		},
		setAttendee({ commit }, data) {
			commit('attendee', data)
		},
		setBigSlideRatio({ commit }, data) {
			commit('bigSlideRatio', data)
		},
		setCurrentSlide({ commit }, data) {
			commit('currentSlide', data)
		},
		setCurrentTime({ commit }, data) {
			commit('currentTime', data)
		},
		setQuestionPassword({ commit }, data) {
			if(data) {
				sessionStorage.setItem('questionPassword', data)
			} else {
				sessionStorage.removeItem('questionPassword')
			}
			commit('questionPassword', data)
		},
		setQuestions({ commit }, data) {
			commit('questions', data)
		},
		setQuestionType({ commit }, data) {
			commit('questionType', data)
		},
		setStream({ commit }, data) {
			commit('stream', data)
		},
		sidebar({ commit }, data) {
			commit('sidebar', data)
		},
		type({ commit }, data) {
			return new Promise((resolve, reject) => {
				let url = `public/type/${data.slug}`
				Vue.axios.get(url, {
					params: {
						token: data.token,
						password: data.password,
					}
				})
				.then((response) => {
					commit('type', response.data)
					resolve(response.data)
				})
				.catch((error) => {
					reject(error)
				})
			})
		},
		updateQuestion(_, data) {
			return new Promise((resolve, reject) => {
				let url = `public/questions/${data.slug}/${data.id}`
				Vue.axios.post(url, {
					password: data.password,
					moderator_password: data.moderator_password,
					answered: data.answered,
					order: data.order,
					rejected: data.rejected,
					token: data.token
				})
				.then((response) => {
					resolve(response.data)
				})
				.catch((error) => {
					reject(error)
				})
			})
		},
		viewSize({ commit }, val) {
			commit('viewSize', val)
		},
		viewSlide({ commit }, val) {
			commit('viewSlide', val)
		}
    }
})
